<template>
  <div v-loading="isLoading">
    <el-alert
        :closable="false"
        style="margin-bottom: 10px"
        title="请在停车场扩展信息中设置出入口数量后再进行通道配置"
        type="warning"
    >
    </el-alert>
    <easy-card title="入口配置">
      <!-- <icon-button icon="add-select" slot="extra" @click.native="addEntry" /> -->
      <el-table :data="entry" class="common-table" style="width: 100%">
        <el-table-column
            label="编号"
            prop="snCode"
            width="100"
        ></el-table-column>
        <el-table-column label="通道名称" prop="name">
          <template slot-scope="scope">
            <el-input v-model="scope.row.name" placeholder="通道名称"/>
          </template>
        </el-table-column>
        <el-table-column label="设备IP地址" prop="ipAddress">
          <template slot-scope="scope">
            <el-input v-model="scope.row.ipAddress" placeholder="设备IP地址"/>
          </template>
        </el-table-column>
        <el-table-column label="登录账号" prop="cameraAccount">
          <template slot-scope="scope">
            <el-input
                v-model="scope.row.cameraAccount"
                placeholder="登录账号"
            />
          </template>
        </el-table-column>
        <el-table-column label="登录密码" prop="cameraPassword">
          <template slot-scope="scope">
            <el-input
                v-model="scope.row.cameraPassword"
                placeholder="登录密码"
            />
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="100">
          <template slot-scope="scope">
            <el-button
                class="common-text"
                size="small"
                style="font-size: 14px"
                type="text"
                @click="goDeleteIn(scope.row)"
            >删除
            </el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </easy-card>
    <easy-card title="出口配置">
      <!-- <icon-button icon="add-select" slot="extra" @click.native="addOut" /> -->
      <el-table :data="out" class="common-table" style="width: 100%">
        <el-table-column
            label="编号"
            prop="snCode"
            width="100"
        ></el-table-column>
        <el-table-column label="通道名称" prop="name">
          <template slot-scope="scope">
            <el-input v-model="scope.row.name" placeholder="通道名称"/>
          </template>
        </el-table-column>
        <el-table-column label="设备IP地址" prop="ipAddress">
          <template slot-scope="scope">
            <el-input v-model="scope.row.ipAddress" placeholder="设备IP地址"/>
          </template>
        </el-table-column>
        <el-table-column label="登录账号" prop="cameraAccount">
          <template slot-scope="scope">
            <el-input
                v-model="scope.row.cameraAccount"
                placeholder="登录账号"
            />
          </template>
        </el-table-column>
        <el-table-column label="登录密码" prop="cameraPassword">
          <template slot-scope="scope">
            <el-input
                v-model="scope.row.cameraPassword"
                placeholder="登录密码"
            />
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="100">
          <template slot-scope="scope">
            <el-button
                class="common-text"
                size="small"
                style="font-size: 14px"
                type="text"
                @click="goDeleteOut(scope.row)"
            >删除
            </el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </easy-card>
    <el-button
        class="xxd-button_save"
        type="primary"
        @click="createGangtingDevice"
    >保存
    </el-button
    >
  </div>
</template>

<script>
import {createGangtingDevice, deleteGangtingDevice, getGangtingDeviceList, getParkingLotInfoById,} from "@/api/parking";

export default {
  name: "passage",
  props: {
    // parkingId: String,
    // entryNumber: {
    //   type: Number,
    //   default: 0,
    // },
    // outNumber: {
    //   type: Number,
    //   default: 0,
    // },
  },
  data() {
    return {
      parkingId: "",
      entry: [],
      out: [],
      // entryNumber: 0,
      // outNumber: 0,
      isLoading: false,
      entrySnCodeNumber: 100,
      outSnCodeNumber: 100,
    };
  },
  watch: {
    // parkingId(newVal) {
    //   if (newVal) {
    //     this.getNumber();
    //     this.getGangtingDeviceList();
    //   }
    // },
  },
  methods: {
    //删除
    goDeleteIn(e) {
      if (e.id === "") {
        const entry = this.entry;
        entry.splice(entry.indexOf(e), 1);
      } else {
        deleteGangtingDevice(e.id).then((res) => {
          if (res && res.code === 30 && res.result) {
            const entry = this.entry;
            entry.splice(entry.indexOf(e), 1);
          }
        });
      }
    },
    goDeleteOut(e) {
      if (e.id === "") {
        const out = this.out;
        out.splice(out.indexOf(e), 1);
      } else {
        deleteGangtingDevice(e.id).then((res) => {
          if (res && res.code === 30 && res.result) {
            const out = this.out;
            out.splice(out.indexOf(e), 1);
          }
        });
      }
    },
    async createGangtingDevice() {
      const params = {
        parkId: this.parkingId,
        deviceConfigList: [].concat(...this.entry, ...this.out),
      };

      // 校验空值的情况
      const validLength = params.deviceConfigList.filter((item) => {
        return (
            item.ipAddress === "" ||
            item.cameraAccount === "" ||
            item.cameraPassword === ""
        );
      }).length;

      if (params.deviceConfigList.length === 0) {
        this.$message({
          message: "暂无可配置的通道",
          type: "warning",
        });
        return;
      }

      if (validLength > 0) {
        this.$message({
          message: "通道配置有存在空值的情况",
          type: "warning",
        });
        return;
      }

      this.isLoading = true;
      const res = await createGangtingDevice(params);
      this.isLoading = false;
      if (res && res.code === 30 && res.result) {
        this.$message({
          message: "保存通道配置成功!",
          type: "success",
        });
        this.$emit("success", false);
      }
    },
    //获取通道出入口数量
    // async getNumber() {
    //   const res = await getParkingLotInfoById(this.parkingId);
    //   if (res && res.code === 30 && res.result) {
    //     const returnObject = res.returnObject;
    //     this.entryNumber = returnObject.entryNumber;
    //     this.outNumber = returnObject.exportNumber;
    //   }
    // },
    getParkingPassageList() {
      this.entry = [];
      this.out = [];

      this.isLoading = true;
      Promise.all([
        getParkingLotInfoById(this.parkingId),
        getGangtingDeviceList(this.parkingId),
      ])
          .then((resList) => {
            this.isLoading = false;

            const getNumberRes = resList[0];
            const getListRes = resList[1];

            let parkingEntryNum = 0;
            let parkingOutNum = 0;

            if (getNumberRes.code === 30 && getNumberRes.result) {
              parkingEntryNum = getNumberRes.returnObject.entryNumber;
              parkingOutNum = getNumberRes.returnObject.exportNumber;
            }

            if (getListRes.code === 30 && getListRes.result) {
              const returnObject = getListRes.returnObject;

              if (returnObject.length > 0) {
                this.entry = returnObject
                    .filter((item) => item.deviceType === "1")
                    .map((item) => {
                      return {
                        snCode: item.sncode,
                        deviceType: item.deviceType,
                        ipAddress: item.ipAddress,
                        cameraAccount: item.cameraAccount,
                        cameraPassword: item.cameraPassword,
                        name: item.name,
                        id: item.id,
                      };
                    });

                this.out = returnObject
                    .filter((item) => item.deviceType === "2")
                    .map((item) => {
                      return {
                        snCode: item.sncode,
                        deviceType: item.deviceType,
                        ipAddress: item.ipAddress,
                        cameraAccount: item.cameraAccount,
                        cameraPassword: item.cameraPassword,
                        name: item.name,
                        id: item.id,
                      };
                    });
              }
            }

            if (this.entry.length < parkingEntryNum) {
              for (let i = this.entry.length; i < parkingEntryNum; i++) {
                this.addEntry();
              }
            }

            if (this.out.length < parkingOutNum) {
              for (let i = this.out.length; i < parkingOutNum; i++) {
                this.addOut();
              }
            }
          })
          .catch((e) => console.log(e));
    },
    // 获取岗亭端通道列表
    // async getGangtingDeviceList() {
    //   this.entry = [];
    //   this.out = [];
    //   this.isLoading = true;
    //   const res = await getGangtingDeviceList(this.parkingId);
    //   this.isLoading = false;
    //   debugger;
    //   if (res && res.code === 30 && res.result) {
    //     const returnObject = res.returnObject;
    //     if (returnObject.length > 0) {
    //       // Todo 若已经存在通道列表
    //       // 需要根据deviceType将其分别存到entry以及out两个数组中
    //       this.entry = returnObject
    //         .filter((item) => item.deviceType === "1")
    //         .map((item) => {
    //           return {
    //             snCode: item.sncode,
    //             deviceType: item.deviceType,
    //             ipAddress: item.ipAddress,
    //             cameraAccount: item.cameraAccount,
    //             cameraPassword: item.cameraPassword,
    //             name: item.name,
    //             id: item.id,
    //           };
    //         });

    //       if (this.entry.length < this.entryNumber) {
    //         for (let i = this.entry.length; i < this.entryNumber; i++) {
    //           this.addEntry();
    //         }
    //       }

    //       this.out = returnObject
    //         .filter((item) => item.deviceType === "2")
    //         .map((item) => {
    //           return {
    //             snCode: item.sncode,
    //             deviceType: item.deviceType,
    //             ipAddress: item.ipAddress,
    //             cameraAccount: item.cameraAccount,
    //             cameraPassword: item.cameraPassword,
    //             name: item.name,
    //             id: item.id,
    //           };
    //         });

    //       if (this.out.length < this.outNumber) {
    //         for (let i = this.out.length; i < this.outNumber; i++) {
    //           this.addOut();
    //         }
    //       }
    //     } else {
    //       for (let i = 0; i < this.entryNumber; i++) {
    //         this.addEntry();
    //       }
    //       for (let i = 0; i < this.outNumber; i++) {
    //         this.addOut();
    //       }
    //     }
    //   }
    // },
    addEntry() {
      // 先判断数组长度 数组长度为0 => 101
      // 不为0 101 + 1 = 102
      //
      if (this.entry.length === 0) {
        this.entrySnCodeNumber = 100;
      } else {
        let snCodeNumber = this.entry[this.entry.length - 1].snCode;
        let number = snCodeNumber.substring(3);
        this.entrySnCodeNumber = parseInt(number);
      }

      this.entry.push({
        id: "",
        snCode: "IN-" + (this.entrySnCodeNumber + 1),
        // this.entry.length >= 10
        //   ? "IN-1" + (this.entry.length + 1)
        //   : "IN-1" + "0" + (this.entry.length + 1),
        deviceType: "1",
        ipAddress: "",
        cameraAccount: "",
        cameraPassword: "",
        name: "",
      });
    },
    addOut() {
      if (this.out.length === 0) {
        this.outSnCodeNumber = 100;
      } else {
        let snCodeNumber = this.out[this.out.length - 1].snCode;
        let number = snCodeNumber.substring(4);
        this.outSnCodeNumber = parseInt(number);
      }

      this.out.push({
        id: "",
        snCode: "OUT-" + (this.outSnCodeNumber + 1),
        // this.out.length >= 10
        //   ? "OUT-1" + (this.out.length + 1)
        //   : "OUT-1" + "0" + (this.out.length + 1),
        deviceType: "2",
        ipAddress: "",
        cameraAccount: "",
        cameraPassword: "",
        name: "",
      });
    },
  },
  mounted() {
    // if (this.parkingId) {
    //   this.getNumber();
    //   this.getGangtingDeviceList();
    // }
  },
};
</script>

<style lang="less" scoped>
</style>
